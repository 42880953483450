(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Shiny"), require("jQuery"));
	else if(typeof define === 'function' && define.amd)
		define(["Shiny", "jQuery"], factory);
	else if(typeof exports === 'object')
		exports["waiter"] = factory(require("Shiny"), require("jQuery"));
	else
		root["waiter"] = factory(root["Shiny"], root["jQuery"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__541__, __WEBPACK_EXTERNAL_MODULE__609__) {
return 